// styling scroll bars
body {
    position: absolute;
    top: 0;
    left: 0;
    right: 1px;
    //overflow-y: scroll;
    //overflow-x: hidden;
    //z-index: 1;
}
html {
    //overflow-y: auto;
    background-color: transparent;
}
::-webkit-scrollbar {
    width: 4px;
    height: 4px;
}
::-webkit-scrollbar-button:start:decrement,
::-webkit-scrollbar-button:end:increment  {
    height: 2px;
    display: block;
    background-color: transparent;
}
::-webkit-scrollbar-track-piece  {
    background-color: #fff;
    -webkit-border-radius: 0px;
}
::-webkit-scrollbar-thumb:vertical,
::-webkit-scrollbar-thumb:horizontal {
    height: 10px;
    background-color: #000;
    border: none;
    -webkit-border-radius: 0px;
}
